import React from "react";

function CombinedSortIcon() {
  
  
  return (
    <span role="image">
      <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.57157 2.78578L3.00014 0.214355L0.428711 2.78578H5.57157ZM0.428711 6.21436L3.00014 8.78578L5.57157 6.21436H0.428711Z" fill="#717171"/>
      </svg>
    </span>
  );
}

export default CombinedSortIcon;
