import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import useSWR from "swr";

import { swrFetcher } from "../api/customFetch";

const IS_SERVER = typeof window === "undefined";


function useServerSafeSWR(key, shouldRunOnServer = true) {
  const dispatch = useDispatch();
  const shouldTrack = useSelector(state => state.ssr.shouldTrack);
  const initialData = useSelector(state => state.ssr.responses[key || ""]);
  const accessToken = useSelector(state => state.ssr.accessToken);

  // SWR hook for client side
  const swrState = useSWR(key, { initialData });

  // Promise for SSR
  if (shouldTrack && IS_SERVER && key && shouldRunOnServer) {
    useMemo(() => {
      let promiseResolve = () => {
        // empty promise
      };
      const promise = new Promise(resolve => promiseResolve = resolve);
      dispatch({ type: "ADD_PROMISE", key, promise });

      // Pass auth token if exists
      const options = {};
      if (accessToken) {
        options.headers = { Authorization: `JWT ${accessToken}` }
      }

      // Query endpoint and wait for response
      swrFetcher(key, options)
        .then(data => dispatch({ type: "ADD_API_RESPONSE", key, data }))
        .catch(() => dispatch({ type: "ADD_API_RESPONSE", key, data: "error" }))
        .finally(promiseResolve)
    }, []);
  }

  // Return error in SWR if needed
  if (initialData === "error") {
    return { ...swrState, error: "error", data: null };
  }

  return swrState;
}

export default useServerSafeSWR;
