import React from "react";
import { Spin } from "antd";
import PropTypes from "prop-types";

export default function RedSpinner({
  size = "large",
  className = "",
  spinning = true,
  centered,
  style
}) {
  const classNames = `red-spinner ${className} ${centered ? "red-spinner_centered" : ""}`

  return (
    <div className={classNames} style={style}>
      <Spin size={size} spinning={spinning} />
    </div>
  );
}

RedSpinner.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  centered: PropTypes.bool,
  style: PropTypes.object,
};
