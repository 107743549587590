import React, { useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Button, Form, Input, message, Modal } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";

// api
import profiles from "../../api/profiles";

// components
import InfoTooltip from "./InfoTooltip";

// redux
import { openLoginModalRequest } from "../../redux/auth/authActions";
import { createNewSearchEmailPreferenceRequest, setSearchPreferencesValue } from "../../redux/general/generalActions";

import "../../assets/styles/shared/save-search-button.scss";

const MAX_REQUESTS = 1 + Math.floor(Math.random() * 10);


const SaveSearchButton = ({
  isAuthenticated,
  openLoginModal,
  createNewSearchEmailPreference,
  buttonLocation,
  categoryHeading,
  keywordSearch,
  buttonLocationId,
  showTooltip = false,
  handleClick,
  title = "Email me the latest items",
  saveSearchPreferencesValue,
  googleCaptchaKey,
}) => {
  const searchPreferencesParams = { includeInEmail: "DAILY" };

  const [emailsCount, setEmailsCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isDisabled = emailsCount > MAX_REQUESTS;

  if (buttonLocation) {
    searchPreferencesParams[buttonLocation] = buttonLocationId;
  } else {
    searchPreferencesParams.keywordSearch = keywordSearch;
  }

  const handleSubscribeToSearchResults = () => {
    typeof handleClick === "function" && handleClick();

    if (isAuthenticated) {
      createNewSearchEmailPreference(searchPreferencesParams);
    } else if (!isDisabled) {
      setIsModalOpen(true);
    }
  }

  const handleLoginAction = () => {
    openLoginModal();
    saveSearchPreferencesValue(searchPreferencesParams);
    setIsModalOpen(false);
  }

  const handleAnonymousEmailPreference = async (data) => {
    setIsLoading(true);

    profiles.createNewEmailPreferenceAnonymous({ ...searchPreferencesParams, ...data })
      .then(() => {
        setIsModalOpen(false);
        message.success("New email subscription was successfully added!", 2);

        setEmailsCount(emailsCount + 1);
      })
      .catch((err) => {
        message.error(
          err.targetEmail?.targetEmail?.length
            ? err.targetEmail?.targetEmail[0]
            : "Could not add new email subscription",
          2
        );
      })
      .finally(() => {
        setIsLoading(false);
      });

    if (window.grecaptcha) {
      window.grecaptcha.ready(function() {
        window.grecaptcha
          .execute(googleCaptchaKey, { action: "submit" })
          .then(function(token) {
            window.grecaptcha_token = (window.grecaptcha_token || []).concat(token);
          });
      });
    }
  };

  return (
    <>
      <Helmet>
        {!isAuthenticated && (
          <style>{".grecaptcha-badge { visibility: visible; }"}</style>
        )}
      </Helmet>
      <div className="save-search-button--container" data-testid="lant-save-search-button">
        <div
          className={classNames("save-search-button", isDisabled && "disabled")}
          onClick={handleSubscribeToSearchResults}
        >
          {title}
        </div>
        {showTooltip && (
          <InfoTooltip
            title="Get Email alerts when new items are added to this category"
            className="save-search-button--tooltip"
          />
        )}
      </div>
      <Modal
        centered
        title={
          categoryHeading
            ? `Receive Email alerts for new items in ${categoryHeading}`
            : "Subscribe to the newsletter"
        }
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        className="save-search-modal"
      >
        <div className="save-search-modal--body">
          <div className="">
            <img
              className="save-search-modal--icon"
              src={`${process.env.REACT_APP_IMAGES_URL}storefront_images/Mail%403x.svg`}
              alt="subscribe"
              title="subscribe"
              width={44}
              height={42}
            />
            <div className="save-search-modal--text">
              {`Add your email address to receive an email when new items are added to ${
                categoryHeading ? categoryHeading : "this category"
              }`}
            </div>
          </div>
          <Form
            name="basic"
            initialValues={{ targetEmail: "" }}
            onFinish={handleAnonymousEmailPreference}
            autoComplete="off"
          >
            <Form.Item
              name="targetEmail"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input type="email" placeholder="Email" />
            </Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Subscribe
            </Button>
          </Form>
          <div className="save-search-modal--link">
            Do you have an account?
            <span onClick={handleLoginAction}>Log in</span>
          </div>
        </div>
      </Modal>
    </>
  )
}

SaveSearchButton.propTypes = {
  isAuthenticated: PropTypes.bool,
  openLoginModal: PropTypes.func.isRequired,
  saveSearchPreferencesValue: PropTypes.func.isRequired,
  createNewSearchEmailPreference: PropTypes.func.isRequired,
  buttonLocation: PropTypes.string,
  categoryHeading: PropTypes.string,
  keywordSearch: PropTypes.string,
  buttonLocationId: PropTypes.number,
  showTooltip: PropTypes.bool,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  title: PropTypes.string,
}

export default connect(
  state => ({
    isAuthenticated: state.auth.isAuthenticated,
    googleCaptchaKey: state.auth.googleCaptchaKey
  }),
  {
    openLoginModal: openLoginModalRequest,
    saveSearchPreferencesValue: setSearchPreferencesValue,
    createNewSearchEmailPreference: createNewSearchEmailPreferenceRequest,
  }
)(SaveSearchButton);
