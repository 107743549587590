import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { swrFetcher } from "../api/customFetch";
import useServerSafeSWR from "./useServerSafeSWR";
import useQueryParams from "./useQueryParams";


const IS_SERVER = typeof window === "undefined";
const NOT_FOUND_URL = "/not-found";


export const apiCallHelper = (key, dispatch) => {
  return new Promise((resolve) => {
    swrFetcher(key)
      .then(data => {
        dispatch({ type: "ADD_API_RESPONSE", key, data });
        resolve(data)
      })
      .catch(() => {
        dispatch({ type: "ADD_API_RESPONSE", key, data: "error" });
        resolve();
      })
  })
}

const getItemsQueryParams = ({ page, perPage, filter, category, priceMin, priceMax, title }) => {
  if (!category) {
    category = {};
  }

  const queryParams = new URLSearchParams();
  queryParams.set("page", page);
  queryParams.set("page_size", perPage);
  queryParams.set("for_impressions", "true");
  queryParams.set("is_sold", filter === "is_sold");

  if (filter) {
    queryParams.set("ordering", filter);
  }
  if (priceMin) {
    queryParams.set("price_min", priceMin);
  }
  if (priceMax) {
    queryParams.set("price_max", priceMax);
  }
  if (title) {
    queryParams.set("title", decodeURIComponent(title));
  }
  if (category.category) {
    queryParams.set("category", category.category.id);
  }
  else if (category.id) {
    queryParams.set("category", category.id);
  }

  if (category.period) {
    queryParams.set("period", category.period.id);
  }
  if (category.material) {
    queryParams.set("material", category.material.id);
  }
  if (category.origin) {
    queryParams.set("origin", category.origin.id);
  }

  return queryParams.toString();
}

const useCategoryPageSSRHelper = () => {
  const dispatch = useDispatch();
  const shouldTrack = useSelector(state => state.ssr.shouldTrack);
  const { pathname } = useLocation();
  const { categoryUrl, seoCategory1, seoCategory2 } = useParams();
  const [{ page = 1, perPage = 40, filter }] = useQueryParams();

  // Skip cases
  if (!IS_SERVER || !shouldTrack) {
    return;
  }

  // Query all required data
  useMemo(() => {
    const asyncFunction = async () => {
      //eslint-disable-next-line
      let promiseResolve = () => {};
      const promise = new Promise(resolve => promiseResolve = resolve);
      dispatch({ type: "ADD_PROMISE", key: "GET_CATEGORY_PAGE_DATA", promise });


      const isOnAllAntiquesPage = pathname === "/antiques";
      const isOnAntiquesSoldPage = pathname === "/antiques/sold";
      const isOnSubCategory = !!(seoCategory2 || seoCategory1);
      const parallelApiCalls = [];
      let topCategory = null;
      let category = null;


      // Get correct url for categories items
      const isOldUrl = pathname.match(/(\d+)$/);
      const oldUrlItemId = isOldUrl ? +isOldUrl[0] : null
      if (oldUrlItemId) {
        parallelApiCalls.push(apiCallHelper(`/storefront/items/id/${oldUrlItemId}/`, dispatch));
      }


      // Check if we are on SEO Category
      const seoResponse = await apiCallHelper(`/seo/urls/storefront/?url=${pathname.slice(1)}`, dispatch);
      const isNotSeoCategory = !seoResponse;
      const isOnSeoCategory = !!(seoResponse || {}).isSeoCategory;

      if (seoResponse) {
        topCategory = seoResponse.category;
        category = seoResponse;
      }

      // Similar Searches
      const similarSearchesQueryParams = new URLSearchParams();
      if (seoResponse) {
        if (seoResponse.material) {
          similarSearchesQueryParams.set("material", seoResponse.material.id);
        }
        if (seoResponse.origin) {
          similarSearchesQueryParams.set("origin", seoResponse.origin.id);
        }
        if (seoResponse.period) {
          similarSearchesQueryParams.set("period", seoResponse.period.id);
        }
        if (seoResponse.category) {
          similarSearchesQueryParams.set("category", seoResponse.category.id);
        }

        similarSearchesQueryParams.set("exclude", pathname.slice(1));
      }
      parallelApiCalls.push(apiCallHelper(`/seo/urls/storefront/?${similarSearchesQueryParams.toString()}`, dispatch));


      // Get category if is not SEO category
      if (isNotSeoCategory && !isOnAllAntiquesPage && !isOnAntiquesSoldPage) {
        const targetCategoryUrl = seoCategory2 || seoCategory1 || categoryUrl;
        parallelApiCalls.push(apiCallHelper(`/categories/all/${targetCategoryUrl}/`, dispatch));
      }


      // Get top-level category if is not SEO category and not on top-level category already
      if (isNotSeoCategory && isOnSubCategory && !isOnAllAntiquesPage && !isOnAntiquesSoldPage) {
        parallelApiCalls.push(apiCallHelper(`/categories/all/${categoryUrl}/`, dispatch));
      }


      // Wait for categories API calls
      const [categoryResponse, topCategoryResponse] = await Promise.all(parallelApiCalls);
      if (categoryResponse && !seoResponse) {
        category = categoryResponse;
        if (!isOnSubCategory) {
          topCategory = categoryResponse;
        }

        // Check 301 redirect for Sub Category if it doesn't have parent
        const shouldCheckSubCategory301 = isOnSubCategory && categoryResponse && !categoryResponse.parent;
        if (shouldCheckSubCategory301) {
          parallelApiCalls.push(apiCallHelper(`/seo/redirects/?from_url=${pathname.slice(1)}`, dispatch));
        }
      }
      if (topCategoryResponse) {
        topCategory = topCategoryResponse;
      }


      // Get items
      if (isOnAllAntiquesPage || isOnAntiquesSoldPage || category) {
        const properCategoryData = category && category.isSeoCategory ? category : topCategory;

        const itemsQueryParams = getItemsQueryParams({
          page, perPage, filter, category: properCategoryData
        });
        const soldItemsQueryParams = itemsQueryParams.replace("is_sold=false", "is_sold=true");
        let queryParamsToSend = isOnAntiquesSoldPage ? soldItemsQueryParams : itemsQueryParams;
        queryParamsToSend += "&show_in=LOVE_ANTIQUES,BOTH";
        parallelApiCalls.push(apiCallHelper(`/storefront/items/?${queryParamsToSend}`, dispatch));
      }


      // Get sub categories for sidebar
      if (topCategory) {
        const properTopCategoryUrl = topCategory.fullUrl ? topCategory.fullUrl.split("/")[0] : topCategory.url;
        parallelApiCalls.push(apiCallHelper(`/categories/all/${properTopCategoryUrl}/children/`, dispatch));
      }


      // Get sidebar SEO categories if we are on top category or subcategory
      if (!isOnSeoCategory && topCategory) {
        const seoUrlsQuery = new URLSearchParams();
        seoUrlsQuery.set("page", "1");
        seoUrlsQuery.set("category", topCategory.id);

        // Get sidebar periods of top category
        parallelApiCalls.push(
          apiCallHelper(`/storefront/periods/related/?${seoUrlsQuery.toString()}`, dispatch)
        );

        // Get sidebar origins of top category
        parallelApiCalls.push(
          apiCallHelper(`/catalogs/origin/sf/elements/related/?${seoUrlsQuery.toString()}`, dispatch)
        );

        // Get sidebar materials of top category
        parallelApiCalls.push(
          apiCallHelper(`/catalogs/material/sf/elements/related/?${seoUrlsQuery.toString()}`, dispatch)
        );
      }

      // Check 301 redirect if no category
      if (!category || !topCategory) {
        parallelApiCalls.push(
          apiCallHelper(`/seo/redirects/?from_url=${pathname.slice(1)}`, dispatch)
        );
      }


      // Wait for all API calls
      await Promise.all(parallelApiCalls);
      promiseResolve();
    }

    asyncFunction().catch(console.error);
  }, []);
}

const useCategoryPageData = () => {
  const { pathname } = useLocation();
  const { categoryUrl, seoCategory1, seoCategory2 } = useParams();
  const [{ page = 1, perPage = 40, filter, priceMin, priceMax, title, search }] = useQueryParams();

  useCategoryPageSSRHelper();

  const isOnAllAntiquesPage = pathname === "/antiques";
  const isOnAntiquesSoldPage = pathname === "/antiques/sold";
  const isOnSubCategory = !!(seoCategory2 || seoCategory1);
  let shouldRedirectTo = "";

  // Get correct url for categories items
  const oldUrlItemId = useMemo(() => {
    const isOldUrl = pathname.match(/(\d+)$/);

    return isOldUrl ? +isOldUrl[0] : null;
  }, []);
  const {
    data: oldUrlItemResponse,
  } = useServerSafeSWR(oldUrlItemId ? `/storefront/items/id/${oldUrlItemId}/` : null, false);
  if (oldUrlItemResponse) {
    shouldRedirectTo = `/items/listings/${oldUrlItemResponse.url}`;
  }


  // SEO Category
  const {
    data: seoResponse,
    error: seoError
  } = useServerSafeSWR(`/seo/urls/storefront/?url=${pathname.slice(1)}`, false);
  const seoLoading = typeof seoResponse === "undefined" && typeof seoError === "undefined";
  const isNotSeoCategory = !seoLoading && !seoResponse;
  const isOnSeoCategory = !!(seoResponse || {}).isSeoCategory;

  // Similar Searches
  const similarSearchesQueryParams = new URLSearchParams();
  if (seoResponse) {
    if (seoResponse.material) {
      similarSearchesQueryParams.set("material", seoResponse.material.id);
    }
    if (seoResponse.origin) {
      similarSearchesQueryParams.set("origin", seoResponse.origin.id);
    }
    if (seoResponse.period) {
      similarSearchesQueryParams.set("period", seoResponse.period.id);
    }
    if (seoResponse.category) {
      similarSearchesQueryParams.set("category", seoResponse.category.id);
    }
    similarSearchesQueryParams.set("exclude", pathname.slice(1));
  }

  const {
    data: similarSearches,
    error: similarSearchesError
  } = useServerSafeSWR(`/seo/urls/storefront/?${similarSearchesQueryParams.toString()}`, false);
  const similarSearchesLoading = typeof similarSearches === "undefined" && typeof similarSearchesError === "undefined";

  // Category
  const shouldGetCategory = isNotSeoCategory && !isOnAllAntiquesPage && !isOnAntiquesSoldPage;
  const targetCategoryUrl = seoCategory2 || seoCategory1 || categoryUrl;

  const {
    data: categoryResponse,
    error: categoryError,
  } = useServerSafeSWR(shouldGetCategory ? `/categories/all/${targetCategoryUrl}/` : null, false);
  const categoryLoading = typeof categoryResponse === "undefined" && typeof categoryError === "undefined";

  // Check 301 redirect for Sub Category if it doesn't have parent
  const shouldCheckSubCategory301 = isNotSeoCategory && isOnSubCategory && categoryResponse && !categoryResponse.parent;
  const {
    data: subCategory301Response
  } = useServerSafeSWR(shouldCheckSubCategory301 ? `/seo/redirects/?from_url=${pathname.slice(1)}` : null, false);
  if (subCategory301Response) {
    shouldRedirectTo = `/${subCategory301Response.toUrl}`;
  }


  // Top Level Category
  const {
    data: topCategoryResponse,
    error: topCategoryError
  } = useServerSafeSWR(shouldGetCategory ? `/categories/all/${categoryUrl}/` : null, false);
  const topCategoryLoading = typeof topCategoryResponse === "undefined" && typeof topCategoryError === "undefined";

  // Category and Top Category
  const category = useMemo(() => seoResponse || categoryResponse, [seoResponse, categoryResponse]);
  const topCategory = useMemo(() => {
    return (seoResponse || {}).category || topCategoryResponse;
  }, [seoResponse, topCategoryResponse]);
  const categoryNotFound = isNotSeoCategory && !categoryLoading && !category;
  const topCategoryNotFound = isNotSeoCategory && !topCategoryLoading && !topCategory;


  // Trigger 301 redirect if category url doesn't match current pathname
  if (category && category.fullUrl && category.fullUrl !== pathname.slice(1)) {
    shouldRedirectTo = `/${category.fullUrl}`;
  }


  // Items
  const itemsQueryString = useMemo(() => {
    return getItemsQueryParams({ page, perPage, filter, category, priceMin, priceMax, title });
  }, [page, perPage, filter, category, priceMin, priceMax, title]);
  const soldItemsQueryString = itemsQueryString.replace("is_sold=false", "is_sold=true");
  let queryStringToSend = isOnAntiquesSoldPage ? soldItemsQueryString : itemsQueryString;
  const shouldGetItems = isOnAllAntiquesPage || isOnAntiquesSoldPage || category;
  queryStringToSend += "&show_in=LOVE_ANTIQUES,BOTH";
  const {
    data: itemsResponse,
    error: itemsError,
  } = useServerSafeSWR(shouldGetItems ? `/storefront/items/?${queryStringToSend}` : null, false);
  const itemsLoading = typeof itemsResponse === "undefined" && typeof itemsError === "undefined";


  // Get sub categories for sidebar
  const {
    data: topCategoryChildrenResponse,
    error: topCategoryChildrenResponseError,
  } = useServerSafeSWR(topCategory ? `/categories/all/${topCategory.url}/children/` : null, false);
  const topCategoryChildrenLoading = typeof topCategoryChildrenResponse === "undefined" &&
    typeof topCategoryChildrenResponseError === "undefined";


  // Get sidebar SEO categories if we are on top level category
  const seoCategoriesQueryParams = useMemo(() => {
    if (!category) {
      return "";
    }

    const queryParams = new URLSearchParams();
    queryParams.set("page", "1");
    queryParams.set("category", category.id);

    return queryParams.toString();
  }, [category])
  const shouldGetSeoCategories = !!seoCategoriesQueryParams && !isOnSeoCategory;

  // Get sidebar periods of top category
  const {
    data: categoryRelatedPeriods,
    error: categoryRelatedPeriodsError
  } = useServerSafeSWR(
    shouldGetSeoCategories ? `/storefront/periods/related/?${seoCategoriesQueryParams}` : null,
    false
  );
  const categoryRelatedPeriodsLoading = typeof categoryRelatedPeriods === "undefined" &&
    typeof categoryRelatedPeriodsError === "undefined";

  // Get sidebar origins of top category
  const {
    data: categoryRelatedOrigins,
    error: categoryRelatedOriginsError
  } = useServerSafeSWR(
    shouldGetSeoCategories ? `/catalogs/origin/sf/elements/related/?${seoCategoriesQueryParams}` : null,
    false
  );
  const categoryRelatedOriginsLoading = typeof categoryRelatedOrigins === "undefined" &&
    typeof categoryRelatedOriginsError === "undefined";

  // Get sidebar materials of top category
  const {
    data: categoryRelatedMaterials,
    error: categoryRelatedMaterialsError
  } = useServerSafeSWR(
    shouldGetSeoCategories ? `/catalogs/material/sf/elements/related/?${seoCategoriesQueryParams}` : null,
    false
  );
  const categoryRelatedMaterialsLoading = typeof categoryRelatedMaterials === "undefined" &&
    typeof categoryRelatedMaterialsError === "undefined";

  // Check 301 redirect if no category
  const shouldCheck301Redirect = !shouldRedirectTo && (categoryNotFound || topCategoryNotFound);
  const {
    data: category301Response,
    error: category301Error
  } = useServerSafeSWR(
    shouldCheck301Redirect ? `/seo/redirects/?from_url=${pathname.slice(1)}` : null,
    false
  );
  const category301RedirectLoading = typeof category301Response === "undefined" &&
    typeof category301Error === "undefined";
  if (category301Response) {
    shouldRedirectTo = `/${category301Response.toUrl}`;
  }


  // 404 if no category
  if (
    !isOnAllAntiquesPage && !isOnAntiquesSoldPage && categoryNotFound &&
    !category301RedirectLoading && !shouldRedirectTo
  ) {
    shouldRedirectTo = NOT_FOUND_URL;
  }

  if (shouldRedirectTo && shouldRedirectTo !== NOT_FOUND_URL && search) {
    shouldRedirectTo = `${shouldRedirectTo}?search=${search}`;
  }

  if (!shouldRedirectTo && (topCategoryNotFound || itemsError)) {
    shouldRedirectTo = NOT_FOUND_URL;
  }

  if (categoryNotFound && !topCategoryNotFound && topCategory?.url) {
    shouldRedirectTo = `/${topCategory?.url}`;
  }

  //building fetching process variable
  const getLoadingProcess = () => {
    if (shouldGetSeoCategories) {
      return ![
        categoryRelatedPeriodsLoading,
        categoryRelatedOriginsLoading,
        categoryRelatedMaterialsLoading,
        topCategoryChildrenLoading
      ].every(item => !item)
    }

    return similarSearchesLoading;
  }

  return {
    category: category || {},
    categoryLoading: !category && categoryLoading && !isOnAllAntiquesPage && !isOnAntiquesSoldPage,
    loadingProcess: getLoadingProcess(),
    topCategory: topCategory || {},
    items: itemsResponse ? itemsResponse.results : [],
    itemsCount: itemsResponse?.count || 0,
    itemsCountTotal: category?.itemsCount || 0,
    itemsLoading: itemsLoading,
    categoryChildren: topCategoryChildrenResponse || [],
    categoryRelatedPeriods,
    categoryRelatedOrigins,
    categoryRelatedMaterials,
    shouldRedirectTo: shouldRedirectTo,
    similarSearches: similarSearches || [],
    isSeoCategory: !!(!seoLoading && seoResponse)
  };
}

export default useCategoryPageData;
