import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import classNames from "classnames";

const InfoTooltip = ({
  title,
  inputName,
  children,
  useTooltip = true,
  label = "i",
  className,
  ...props
}) => {
  const childrenComponent = children ? (
    children
  ) : (
    <span
      className={
        inputName === "price"
          ? "my-antiques--price-tooltip"
          : "my-antiques--tooltip"
      }
    >
      {label}
    </span>
  );

  if (useTooltip) {
    return (
      <Tooltip
        {...props}
        className={classNames("pointer", className)}
        title={title}
      >
        {childrenComponent}
      </Tooltip>
    );
  }

  return childrenComponent;
};

InfoTooltip.propTypes = {
  title: PropTypes.node,
  inputName: PropTypes.string,
  label: PropTypes.node,
  children: PropTypes.node,
  props: PropTypes.object,
  useTooltip: PropTypes.bool,
};

export default InfoTooltip;
